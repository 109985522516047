<template>
  <div>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100"
          style="gap: 8px;"
        >
          <b-form-group class="mb-0">
            <div class="d-flex align-items-center">
              <label
                class="mr-1"
                style="width:70px;"
              >{{ $t('Search') }}</label>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('Tìm kiếm theo tên thương hiệu')"
                  type="text"
                  class="d-inline-block"
                  style="width: 430px;"
                  @keyup.enter="loadList"
                />
              </b-input-group>
              <!-- <label class="mr-1">{{ $t('Search') }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Tìm kiếm theo tên thương hiệu')"
                type="text"
                class="d-inline-block"
                style="width: 430px;"
                @keyup.enter="loadList"
              /> -->
            </div>
          </b-form-group>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <!-- <CustomTable
          :items="items"
          :fields="fields"
          @handlerSort="handlerSort"
          @handlerDelete="handlerDelete"
        /> -->

        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
          <!-- <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Discount promotion period') }}</label>
              <flat-pickr
                v-model="rangeDate.string"
                class="form-control"
                style="width: 220px;"
                :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
                :placeholder="$t('From - To date')"
                @on-close="onDatePicker"
              />
            </div>
          </b-form-group> -->
        </div>

        <!-- table -->
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table custom-good-table-ctrl-border"
          :columns="fields"
          :rows="items"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'avatar'"
              class="text-nowrap d-flex flex-wrap align-items-center justify-content-center"
            >
              <b-avatar
                :src="props.row.avatar"
                size="lg"
                class="mr-1"
              />
            </span>

            <!-- Column: Type -->
            <span
              v-else-if="props.column.field === 'name'"
              class="d-block"
            >
              <router-link
                class="d-block line-clamp"
                style="max-width: 320px;"
                :title="props.row.name"
                :to="`edit/${props.row.id}?lang=${currentLang}`"
              >
                {{ props.row.name }}
              </router-link>
            </span>

            <!-- Column: Products -->
            <span
              v-else-if="props.column.field === 'position'"
              class="text-nowrap"
            >
              <div
                class="d-flex flex-wrap align-items-center justify-content-center"
                style="gap: 6px;"
              >
                {{ props.row.position }}
              </div>
            </span>

            <!-- Column: Combo Period -->
            <span
              v-else-if="props.column.field === 'link'"
              class="d-block"
              style="min-width: 140px;"
            >{{ props.row.link }}</span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'is_active'">
              <ActiveStatus
                class="d-flex flex-wrap align-items-center justify-content-center"
                :value="props.row.is_active"
              />
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'more'">
              <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
                <b-button
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  :to="`edit/${props.row.id}?lang=${currentLang}`"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="text-danger btn-icon rounded-circle"
                  @click="handlerDelete(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'sort'">
              <template class="text-center">
                <div class="text-center">
                  <feather-icon
                    :class="{ disabled: items[props.index - 1] === undefined }"
                    icon="ArrowUpIcon"
                    class="text-success cursor-pointer mr-1"
                    size="18"
                    @click="handlerSort(props, 'up')"
                  />
                  <feather-icon
                    :class="{ disabled: items[props.index + 1] === undefined }"
                    icon="ArrowDownIcon"
                    class="text-danger cursor-pointer"
                    size="18"
                    @click="handlerSort(props, 'down')"
                  />
                </div>
              </template>
            </span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'lang'">
              <template>
                <div class="text-center">
                  <LanguageSelect
                    :value="props.row"
                    :language-list="languageList"
                  />
                </div>
              </template>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import {
  BButton,
  BCard,
  BCardHeader,
  // BCardTitle,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BAvatar,
  BFormSelect,
  // BBadge,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LanguageSelect from '@/views/components/language-select/language_v2.vue'
import general from '@/mixins/index'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    // flatPickr,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
    BAvatar,
    BFormSelect,
    // BBadge,
    VueGoodTable,
    // BDropdown,
    // BDropdownItem,
    LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      languageList: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      fields: [
        {
          field: 'id_number', label: 'ID', sortable: true, tdClass: 'text-center',
        },
        { field: 'avatar', label: 'Avatar', sortable: false },
        { field: 'name', label: 'Name', sortable: false },
        { field: 'position', label: 'Position', sortable: false },
        { field: 'link', label: 'link', sortable: true },
        { field: 'is_active', label: 'Status', sortable: true },
        { field: 'more', label: 'Tool', sortable: false },
      ],
      items: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-success',
        upComing     : 'light-danger',
        finished     : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  async mounted() {
    this.languageList = await this.loadLanguage()
    this.loadList()
  },
  methods: {
    async handlerSort(data, method) {
      const { row } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [row.id, this.items[data.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [row.id, this.items[data.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: row.id,
              position: Number(row.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademark/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let params = ''
        if (this.searchTerm) {
          params = `&name=${this.searchTerm}`
        }
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademarks?site_id=${siteId}${params}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = await this.getDataTranslation(
              res.data.data.items,
              true,
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/trademark/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Trademark has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.bold
  font-weight: bold

.custom-search
  padding: 0 1.5rem

.card-body
  padding: 0

.ctrl-pd-1-5
  padding: 1.5rem

.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>
<style lang="scss">
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem 2rem !important;
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
